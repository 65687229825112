<template>
    <div class="aa-lectura mb-3" v-if="anuncioDetalle">
        <div class="aa-lectura-img" :style="'background-image: url('+anuncioDetalle.portada+')'">
            <!-- <img :src="anuncioDetalle.portada" /> -->
        </div>

        <div class="anuncio-card">
        <div class="aa-lectura-txt aa-text-h2 mt-2 mb-1 text-start">
            {{anuncioDetalle.titulo}}
        </div>
        <div class="aa-lectura-doc text-start" v-html="anuncioDetalle.cuerpo"></div>
        
        <div class="noticia-multimedia mt-3" v-if="anuncioDetalle.multimedia">
                <video v-if="anuncioDetalle.multimedia[0].mime == 'video/mp4'" controls preload="auto" playsinline>
                    <source :src="anuncioDetalle.multimedia[0].url" type="video/mp4">
                </video>
        </div>

        <DescargaDocumento :documentos="anuncioDetalle.documentos" :titulo="'Documentos'" class="mt-2" />
        </div>

    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { defineAsyncComponent, watch } from 'vue'


import useAnuncios from '../composables/useAnuncios'
import PrensaSk from '../components/skeleton/PrensaSk.vue'


export default {
    components: {
    DescargaDocumento: defineAsyncComponent(() => import("../components/Area/DescargaDocumento.vue")),
    PrensaSk
},
    setup(){
        const route = useRoute()
        const { anuncioDetalle, leeAnuncio } = useAnuncios(route.params.id)
        
        leeAnuncio(route.params.id)

        watch(
            () => route.params.id,
            () => leeAnuncio(route.params.id)
        )

        return{
            anuncioDetalle
        }
    }

}
</script>

<style lang="sass" scoped>
.anuncio-card
    width: 85vw
    padding: 10px
    background-color: #fff
    margin: 0 auto
    position: relative
    top: -60px
    @include breakpoint(md)
        width: 80vw
.aa-lectura-img
    height: 220px
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    overflow: hidden
    margin-top: 96px
    @include breakpoint(md)
        height: 390px
.aa-lectura-doc
    width: 85% !important
    word-break: break-word !important
</style>